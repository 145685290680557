import { axiosClient } from "clients/axios";
import type { InstancePostResponse } from "pages/api/instances";
import type {
  GenderApi,
  InstanceGenderPutBody,
} from "pages/api/instances/[instance_id]/genders";
import type { InstancePermissionPutBody } from "pages/api/instances/[instance_id]/permissions";
import type { InstanceRolePutBody } from "pages/api/instances/[instance_id]/roles";

import type { ApiPostSuccess, ApiPostSuccessData } from "types/ApiResponses";
import type { FlApi } from "types/api";
import { AxiosResponse } from "axios";

/**
 * Fetch an instance by id
 * @param instanceId {number} id of instance
 * @returns
 */
export const getInstance = async (instanceId: number) => {
  const { data } = await axiosClient.get<FlApi.Instance.Instance>(
    `instances/${instanceId}`
  );
  return data;
};

/**
 * Create an instance
 * @description Creates an instance user pool and related tables.
 *   On error, will cleanup after itself.
 * @param id ID of instance to patch
 * @returns InstancePostResponse or ApiError
 */
export const postInstance = async (
  body: FlApi.Instance.InstanceCreateParams
) => {
  const response = await axiosClient.post<InstancePostResponse>(
    "instances",
    body
  );
  return response.data;
};

/**
 * Patch instance values
 * @param id ID of instance to patch
 * @param body Fields that will be overwritten
 * @returns ApiPostSuccess or ApiError
 */
export const patchInstance = async (
  id: number,
  body: FlApi.Instance.InstanceUpdateParams
) => {
  const response = await axiosClient.patch<ApiPostSuccess>(
    `instances/${id}`,
    body
  );
  return response.data;
};

/**
 * Delete an instance
 * @description Deletes an instance user pool and related tables.
 * @param id ID of instance to patch
 * @returns ApiPostSuccess or ApiError
 */
export const deleteInstance = async (id: number) => {
  const response = await axiosClient.delete<ApiPostSuccess>(`instances/${id}`);
  return response.data;
};

/**
 * Put instance roles collection
 * @param id ID of instance to put roles for
 * @param body Array of roles
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceRoles = async (
  id: number,
  body: InstanceRolePutBody
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/roles`,
    body
  );
  return response.data;
};

/**
 * Fetch an instance's genders by id
 * @param instanceId {number} id of instance
 * @returns
 */
export const getInstanceGenders = async (instanceId: number) => {
  const { data } = await axiosClient.get<GenderApi[]>(
    `instances/${instanceId}/genders`
  );
  return data;
};

/**
 * Put instance genders collection
 * @param id ID of instance to put genders for
 * @param body Array of genders
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceGenders = async (
  id: number,
  body: InstanceGenderPutBody
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/genders`,
    body
  );
  return response.data;
};

/**
 * Put instance genders collection
 * @param id ID of instance to put genders for
 * @param body Array of genders
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceReadingLevels = async (
  id: number,
  body: FlApi.Instance.ReadingLevelCreateParams[]
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/reading-levels`,
    body
  );
  return response.data;
};

/**
 * Fetch an instance socio fields by id
 * @param instanceId {number} id of instance
 * @returns
 */
export const getInstanceSocioFields = async (instanceId: number) => {
  const { data } = await axiosClient.get<FlApi.Instance.SocioField[]>(
    `instances/${instanceId}/socio-fields`
  );
  return data;
};

/**
 * Put instance socio fields
 * @param id ID of instance to put genders for
 * @param body Array of socio fields
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceSocioFields = async (
  id: number,
  body: FlApi.Instance.SocioFieldCreateParams[]
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/socio-fields`,
    body
  );
  return response.data;
};

/**
 * Fetch an instance client fields by id
 * @param instanceId {number} id of instance
 * @returns
 */
export const getInstanceClientFields = async (instanceId: number) => {
  const { data } = await axiosClient.get<FlApi.Instance.ClientField[]>(
    `instances/${instanceId}/client-fields`
  );
  return data;
};

/**
 * PaPuttch instance client fields
 * @param id ID of instance to put fields for
 * @param body Array of client fields
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceClientFields = async (
  id: number,
  body: FlApi.Instance.ClientFieldCreateParams[]
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/client-fields`,
    body
  );
  return response.data;
};

export const patchInstanceClientFields = async (
  id: number,
  body: Omit<FlApi.Instance.ClientFieldCreateParams, "language">
) => {
  const response = await axiosClient.patch<ApiPostSuccess>(
    `instances/${id}/client-fields`,
    body
  );
  return response.data;
};

/**
 * Fetch an instance org levels by id
 * @param instanceId {number} id of instance
 * @returns
 */
export const getInstanceOrgLevels = async (instanceId: number) => {
  const { data } = await axiosClient.get<FlApi.OrgLevel.OrgLevel[]>(
    `instances/${instanceId}/org-levels`
  );
  return data;
};

/**
 * Put instance org levels collection
 * @param id ID of instance to put genders for
 * @param body Array of org levels
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceOrgLevels = async (
  id: number,
  body: FlApi.OrgLevel.OrgLevelCreateParams
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/org-levels`,
    body
  );
  return response.data;
};

/**
 * Put instance org levels collection
 * @param id ID of instance to delete org levels for
 * @param organization_level_id Org level Id to delete
 * @returns ApiPostSuccess or ApiError
 */
export const deleteInstanceOrgLevels = async (
  id: number,
  organization_level_id: number
) => {
  const response = await axiosClient.delete<ApiPostSuccess>(
    `instances/${id}/org-levels`,
    { data: { organization_level_id } }
  );
  return response.data;
};

/**
 * Put instance org levels collection
 * @param id ID of instance to put genders for
 * @param body Array of org levels
 * @returns ApiPostSuccess or ApiError
 */
export const putInstancePermissions = async (
  id: number,
  body: InstancePermissionPutBody
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/permissions`,
    body
  );
  return response.data;
};

/**
 * Put instance default questions collection
 * @param id ID of instance to put default questions for
 * @param body Array of default questions
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceDefaultQuestions = async (
  id: number,
  body: FlApi.Instance.DefaultQuestions[]
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/default-questions`,
    body
  );
  return response.data;
};

/**
 * Put instance org levels collection
 * @param id ID of instance to put branding for
 * @param body The branding settings object
 * @returns ApiPostSuccess or ApiError
 */
export const putInstanceBranding = async (
  id: number,
  body: FlApi.Branding.Branding
) => {
  const response = await axiosClient.put<ApiPostSuccess>(
    `instances/${id}/branding`,
    body
  );
  return response.data;
};

export const rerunTestResults = async (body: { instance_id: number }) => {
  let completedCall = false;
  let currentOffset = 0;
  let response: AxiosResponse<
    ApiPostSuccessData<{ completed: boolean; offset: number }>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  > | null = null;
  while (!completedCall) {
    // eslint-disable-next-line no-await-in-loop
    response = await axiosClient.post<
      ApiPostSuccessData<{ completed: boolean; offset: number }>
    >(`test-algo`, { instance_id: body.instance_id, offset: currentOffset });
    completedCall = response.data.data.completed;
    currentOffset = response.data.data.offset;
  }

  return response?.data ?? {};
};
