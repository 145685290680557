import type { AppProps } from "next/app";

import { Providers } from "components/providers/Providers";

import { useMarker } from "hooks/useMarker";

const Application = ({ Component, pageProps }: AppProps) => {
  useMarker();

  return (
    <Providers>
      <Component {...pageProps} />
    </Providers>
  );
};

export default Application;
