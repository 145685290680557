import React, { createContext, useEffect } from "react";

interface HistoricRange {
  isHistoricView: boolean;
  selectedTest: number | null;
  dateRange1SliderValues: number[];
  dateRange2SliderValues: number[];
}

export interface HistoricRangeContextValue {
  init: boolean;
  isHistoricView: boolean;
  selectedTest: number | null;
  dateRange1SliderValues: number[];
  dateRange2SliderValues: number[];
  previousTest: React.MutableRefObject<number | null>;
  setIsHistoricView: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTest: React.Dispatch<React.SetStateAction<number | null>>;
  setDateRange1SliderValues: React.Dispatch<React.SetStateAction<number[]>>;
  setDateRange2SliderValues: React.Dispatch<React.SetStateAction<number[]>>;
}

/**
 * Restore filters from local storage
 *   In case storedData is invalid, we catch the JSON error and return empty object
 * @returns Filters from local storage
 */
const restoreFilters = (): HistoricRange => {
  let historicRange = {} as HistoricRange;
  try {
    const storedData: string | null =
      window.localStorage.getItem("historicRanges");
    if (storedData) {
      historicRange = JSON.parse(storedData) as HistoricRange;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return historicRange;
};

const storeFilters = (historicRange: HistoricRange) => {
  window.localStorage.setItem("historicRanges", JSON.stringify(historicRange));
};

export const HistoricRangeContext = createContext<HistoricRangeContextValue>({
  isHistoricView: false,
  selectedTest: null,
  dateRange1SliderValues: [0, 1],
  dateRange2SliderValues: [0, 1],
  init: false,
  previousTest: { current: null },
  setIsHistoricView: () => {
    // Do nothing
  },
  setSelectedTest: () => {
    // Do nothing
  },
  setDateRange1SliderValues: () => {
    // Do nothing
  },
  setDateRange2SliderValues: () => {
    // Do nothing
  },
});

export const HistoricRangeProvider: React.FC = (props) => {
  const { children } = props;

  const [isHistoricView, setIsHistoricView] = React.useState(false);
  const [selectedTest, setSelectedTest] = React.useState<number | null>(null);
  const [dateRange1SliderValues, setDateRange1SliderValues] = React.useState<
    number[]
  >([0, 1]);
  const [dateRange2SliderValues, setDateRange2SliderValues] = React.useState<
    number[]
  >([0, 1]);

  const [init, setInit] = React.useState(false);

  const previousTest = React.useRef(selectedTest);

  useEffect(() => {
    previousTest.current = selectedTest;
  }, [selectedTest]);

  useEffect(() => {
    const restoredFilters = restoreFilters();

    if (Object.keys(restoredFilters).length > 0) {
      setIsHistoricView(restoredFilters.isHistoricView);
      setSelectedTest(restoredFilters.selectedTest);
      setDateRange1SliderValues(restoredFilters.dateRange1SliderValues);
      setDateRange2SliderValues(restoredFilters.dateRange2SliderValues);
    }
    setTimeout(() => {
      setInit(true);
    });
  }, []);

  useEffect(() => {
    if (init) {
      const historicRange = {
        isHistoricView,
        selectedTest,
        dateRange1SliderValues,
        dateRange2SliderValues,
      };
      storeFilters(historicRange);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isHistoricView,
    selectedTest,
    dateRange1SliderValues,
    dateRange2SliderValues,
  ]);

  const value = React.useMemo(
    () => ({
      isHistoricView,
      selectedTest,
      dateRange1SliderValues,
      dateRange2SliderValues,
      init,
      previousTest,
      setIsHistoricView,
      setSelectedTest,
      setDateRange1SliderValues,
      setDateRange2SliderValues,
    }),
    [
      isHistoricView,
      selectedTest,
      dateRange1SliderValues,
      dateRange2SliderValues,
      init,
      previousTest,
      setIsHistoricView,
      setSelectedTest,
      setDateRange1SliderValues,
      setDateRange2SliderValues,
    ]
  );

  return (
    <HistoricRangeContext.Provider value={value}>
      {children}
    </HistoricRangeContext.Provider>
  );
};
