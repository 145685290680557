/* eslint-disable @typescript-eslint/no-floating-promises */
import i18next from "i18next";
import languageStatic from "language_static.json";
import React from "react";
import { initReactI18next } from "react-i18next";
import useSWRImmutable from "swr/immutable";

import { Loading } from "components/Loading";

import { useSettings } from "hooks/useSettings";
import { useSlugRouter } from "hooks/useSlugRouter";

import { LanguageJson } from "types/Tables";

export const LanguageProvider: React.FC = ({ children }) => {
  const { slug } = useSlugRouter();
  const { settings } = useSettings();

  const { data: language } = useSWRImmutable<LanguageJson>(
    slug.length ? `language/${slug}` : null
  );

  const [init, setInit] = React.useState(false);

  /**
   * Allow adjusting language
   */
  React.useEffect(() => {
    if (init && settings.language && i18next.language !== settings.language) {
      i18next.changeLanguage(settings.language);
    }
  }, [settings.language, init]);

  /**
   * Add the language from the api
   */
  React.useEffect(() => {
    if (init && language) {
      Object.entries(language).forEach(([key, value]) => {
        i18next.addResources(key, "translation", value);
      });
      i18next.changeLanguage(i18next.language);
    }
  }, [language, init]);

  /**
   * Init and add static language
   */
  React.useEffect(() => {
    i18next
      .use(initReactI18next)
      .init({
        resources: languageStatic,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
      })
      .then(() => {
        setInit(true);
      });
  }, []);

  /**
   * Display loading state while not initialized
   */
  if (!init) {
    return <Loading open />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
