import { axiosClient } from "clients/axios";
import {
  NewPasswordBody,
  NewPasswordResponse,
} from "pages/api/auth/change-password";
import {
  ForgotPasswordBody,
  ForgotPasswordResponse,
} from "pages/api/auth/forgot-password";
import { ResetPasswordBody } from "pages/api/auth/forgot-password-reset";
import {
  AuthLoginPostBody,
  AuthLoginPostResponse,
  CurrentSession,
} from "pages/api/auth/login";
import { ClientPermissions } from "utils/database/get-permissions";

import { ApiPostSuccess } from "types/ApiResponses";
import { FlApi } from "types/api";

export const createNewPassword = async (body: NewPasswordBody) => {
  const response = await axiosClient.post<NewPasswordResponse>(
    "auth/change-password",
    body
  );
  return response.data;
};

export const login = async (body: AuthLoginPostBody) => {
  const response = await axiosClient.post<AuthLoginPostResponse>(
    "auth/login",
    body
  );
  return response.data;
};

export const fetchCurrentSession = async () => {
  const response = await axiosClient.get<CurrentSession>("auth/login");
  return response.data;
};

export const logout = async () => {
  await axiosClient.post("auth/logout");
};

export const forgotPassword = async (body: ForgotPasswordBody) => {
  const response = await axiosClient.post<ForgotPasswordResponse>(
    "auth/forgot-password",
    body
  );
  return response.data;
};

export const resetPassword = async (body: ResetPasswordBody) => {
  const response = await axiosClient.post<ClientPermissions>(
    "auth/forgot-password-reset",
    body
  );
  return response.data;
};

export const newPassword = async (body: NewPasswordBody) => {
  const response = await axiosClient.post<ClientPermissions>(
    "auth/change-password",
    body
  );
  return response.data;
};

export const updateMfa = async (params: FlApi.Auth.MfaUpdateParams) => {
  const response = await axiosClient.post<FlApi.Auth.MfaUpdateResponse>(
    `auth/mfa`,
    params
  );

  return response.data;
};

export const setupMfa = async (params: FlApi.Auth.MfaSetupParams) => {
  const response = await axiosClient.post<ApiPostSuccess>(
    `auth/mfa-setup`,
    params
  );

  return response.data;
};
