import * as React from "react";

import { useAuth } from "hooks/useAuth";
import { GenderApi } from "pages/api/instances/[instance_id]/genders";
import {
  getInstance,
  getInstanceClientFields,
  getInstanceGenders,
  getInstanceOrgLevels,
  getInstanceSocioFields,
} from "requests/instances";

import { FlApi } from "types/api";

interface InstanceContextState {
  instance: FlApi.Instance.Instance | null;
  socioFields: FlApi.Instance.SocioField[];
  clientFields: FlApi.Instance.ClientField[];
  genders: GenderApi[];
  orgLevels: FlApi.OrgLevel.OrgLevel[];
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: InstanceContextState;
};

type InstanceContextAction = InitializeAction;

const initialState: InstanceContextState = {
  instance: null,
  socioFields: [],
  clientFields: [],
  genders: [],
  orgLevels: [],
};

const reducer = (
  state: InstanceContextState,
  action: InstanceContextAction
): InstanceContextState => {
  switch (action.type) {
    case "INITIALIZE": {
      const { clientFields, instance, socioFields, genders, orgLevels } =
        action.payload;

      return {
        ...state,
        clientFields,
        instance,
        socioFields,
        genders,
        orgLevels,
      };
    }
    default:
      return { ...state };
  }
};

export const InstanceContext = React.createContext<InstanceContextState>({
  ...initialState,
});

export const InstanceProvider: React.FC = (props) => {
  const { children } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { instanceId } = useAuth();

  React.useEffect(() => {
    const initialize = async (): Promise<void> => {
      if (!instanceId) {
        return;
      }

      try {
        const [instance, genders, socioFields, clientFields, orgLevels] =
          await Promise.all([
            getInstance(instanceId),
            getInstanceGenders(instanceId),
            getInstanceSocioFields(instanceId),
            getInstanceClientFields(instanceId),
            getInstanceOrgLevels(instanceId),
          ]);

        dispatch({
          type: "INITIALIZE",
          payload: {
            instance,
            genders,
            socioFields,
            clientFields,
            orgLevels,
          },
        });
      } catch (error) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            instance: null,
            clientFields: [],
            socioFields: [],
            genders: [],
            orgLevels: [],
          },
        });
      }
    };

    if (!state.instance && typeof instanceId === "number") {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      initialize();
    }
  }, [state.instance, instanceId]);

  const value = React.useMemo(
    () => ({
      ...state,
    }),
    [state]
  );

  return (
    <InstanceContext.Provider value={value}>
      {children}
    </InstanceContext.Provider>
  );
};
