import type { Theme, ThemeOptions } from "@mui/material";
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import merge from "lodash/merge";

import { THEMES } from "../constants/themes";
import { lightShadows, darkShadows } from "./shadows";

import { Settings } from "contexts/SettingsContext";

declare module "@mui/material/styles" {
  type DefaultTheme = Theme;

  interface PaletteColor {
    theme?: string;
  }

  interface SimplePaletteColorOptions {
    theme?: string;
  }
  interface TypeBackground {
    border?: string;
  }
}

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const baseOptions: ThemeOptions = {
  direction: "ltr",
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: "75%",
          width: "75%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: "16px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: { verticalAlign: "bottom" },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '"Tajawal", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 500,
      fontSize: "1.125rem",
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = (
  primaryMain: string | null | undefined
): Record<string, ThemeOptions> => ({
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
          },
        },
      },
    },
    palette: {
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#f4f5f7",
        paper: "#ffffff",
        border: "#DBDBDB",
      },
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "light",
      primary: {
        contrastText: "#ffffff",
        main: primaryMain ?? "#537B8D",
        theme: "#537B8D",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#172b4d",
        secondary: "#6b778c",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
          },
        },
      },
    },
    palette: {
      background: {
        default: "#171c24",
        paper: "#222b36",
        border: "#5E656C",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      mode: "dark",
      primary: {
        contrastText: "#ffffff",
        main: primaryMain ?? "#688eff",
        theme: "#537B8D",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#ffffff",
        secondary: "#919eab",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: darkShadows,
  },
});

export const createTheme = (
  config: Pick<Settings, "responsiveFontSizes" | "roundedCorners" | "theme">,
  primaryMain: string | null | undefined
): Theme => {
  const selectedTheme = config.theme ?? THEMES.LIGHT;

  // Be extra sure it exists
  let themeOptions = themesOptions(primaryMain)[selectedTheme];
  if (!themeOptions) {
    themeOptions = themesOptions(primaryMain)[THEMES.LIGHT];
  }

  const theme = createMuiTheme(
    merge({}, baseOptions, themeOptions, {
      ...(config.roundedCorners && {
        shape: {
          borderRadius: 16,
        },
      }),
    })
  );

  if (config.responsiveFontSizes) {
    return responsiveFontSizes(theme);
  }

  return theme;
};
