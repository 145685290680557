import { useRouter } from "next/router";
import * as React from "react";

/**
 * UseSlugRouter
 * Router that wraps appending of slug
 * @returns Use router args, with modified push
 */
export const useSlugRouter = () => {
  const router = useRouter();

  const slug = React.useMemo(
    () => (typeof router.query.slug === "string" ? router.query.slug : ""),
    [router.query.slug]
  );

  const push = async (...args: Parameters<typeof router.push>) => {
    // Extract url
    let [url, alias] = args;

    // Append where needed
    if (typeof slug === "string") {
      if (typeof url === "string") {
        url = `/${slug}${url}`;
      } else if (typeof url.pathname === "string") {
        url.pathname = `/${slug}${url.pathname}`;
      }

      if (typeof alias === "string") {
        alias = `/${slug}${alias}`;
      } else if (typeof alias?.pathname === "string") {
        alias.pathname = `/${slug}${alias.pathname}`;
      }
    }

    // Forward args
    return router.push(url, alias, args[2]);
  };

  return {
    ...router,
    push,
    slug,
  };
};
