import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { ApiError } from "types/ApiErrors";

// The main axios client
export const axiosClient = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_URL ?? ""}/api/`,
});

// Fetcher for user with useSWR
export const fetcher = async <T>(url: string, config?: AxiosRequestConfig) => {
  try {
    const response = await axiosClient.get<T>(url, config);
    return response.data;
  } catch (error) {
    const { response } = error as AxiosError;
    throw response?.data;
  }
};

// Refresh the client token if expired
axiosClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ApiError>) => {
    const originalRequest = error.config;

    // Only refresh if we need to
    if (
      error.response?.status === 401 &&
      error.response?.data.code === "TOKEN_EXPIRED"
    ) {
      return axios.post("/api/auth/refresh").then((res) => {
        if (res.status === 201) {
          return axios(originalRequest);
        }
        window.location.href = "/?error=token_expired";
        return null;
      });
    }

    return Promise.reject(error);
  }
);
