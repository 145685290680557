import React, { createContext, useEffect, useReducer } from "react";
import type { FC } from "react";

import { AuthContextAction } from "./AuthContextActions";

import { NewPasswordBody } from "pages/api/auth/change-password";
import {
  ForgotPasswordBody,
  ForgotPasswordResponse,
} from "pages/api/auth/forgot-password";
import { ResetPasswordBody } from "pages/api/auth/forgot-password-reset";
import { AuthLoginPostBody } from "pages/api/auth/login";
import * as AuthRequests from "requests/auth";
import { ClientPermissions } from "utils/database/get-permissions";

import { Client } from "types/Tables";

/**
 * Type of AuthContext state
 */
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  client: Client | null;
  permissions: ClientPermissions;
  instanceId?: number;
  // Base student is referring to bottom level student role without an email address
  isBaseStudent: boolean | null;
}

/**
 * Extended state with functions to mutate state
 */
interface AuthContextState extends State {
  login: (body: AuthLoginPostBody) => Promise<string | undefined>;
  logout: () => Promise<void>;
  forgotPassword: (body: ForgotPasswordBody) => Promise<ForgotPasswordResponse>;
  forgotPasswordReset: (body: ResetPasswordBody) => Promise<void>;
  newPassword: (body: NewPasswordBody) => Promise<void>;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  client: null,
  permissions: {
    actions: [],
    menus: [],
    customMenus: [],
    roles: [],
  },
  isBaseStudent: null,
};

const reducer = (state: State, action: AuthContextAction): State => {
  switch (action.type) {
    case "INITIALIZE": {
      const { isAuthenticated, client, permissions, instanceId } =
        action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        client,
        permissions: {
          ...state.permissions,
          ...permissions,
        },
        instanceId,
        isBaseStudent: !(
          Boolean(
            permissions?.menus.some((m) => m.menu_id === "user_dashboard")
          ) ||
          Boolean(
            permissions?.menus.some((m) => m.menu_id === "admin_dashboard")
          )
        ),
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: false,
      };
    }
    case "LOGOUT":
      return {
        ...initialState,
        isAuthenticated: false,
        isInitialized: false,
      };
    case "PASSWORD_RECOVERY":
      return { ...state };
    case "PASSWORD_RESET":
      return { ...state };
    case "NEW_PASSWORD":
      return { ...state };
    default:
      return { ...state };
  }
};

export const AuthContext = createContext<AuthContextState>({
  ...initialState,
  login: async () => Promise.resolve(undefined),
  logout: async () => Promise.resolve(),
  forgotPassword: async () => Promise.resolve({}),
  forgotPasswordReset: async () => Promise.resolve(),
  newPassword: async () => Promise.resolve(),
});

export const AuthProvider: FC = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const currentSession = await AuthRequests.fetchCurrentSession();

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            client: currentSession.client,
            permissions: currentSession.permissions,
            instanceId: currentSession.instance_id,
          },
        });
      } catch (error) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            client: null,
            permissions: null,
          },
        });
      }
    };

    if (!state.isInitialized) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      initialize();
    }
  }, [state.isInitialized]);

  const login = async (body: AuthLoginPostBody): Promise<string> => {
    const { valid, status } = await AuthRequests.login(body);
    dispatch({
      type: "LOGIN",
    });
    return valid ? (status as string) : "";
  };

  const logout = async (): Promise<void> => {
    await AuthRequests.logout();
    dispatch({
      type: "LOGOUT",
    });
  };

  const forgotPassword = async (
    body: ForgotPasswordBody
  ): Promise<ForgotPasswordResponse> => {
    const response = await AuthRequests.forgotPassword(body);
    dispatch({
      type: "PASSWORD_RECOVERY",
    });
    return response;
  };

  const forgotPasswordReset = async (body: ResetPasswordBody) => {
    await AuthRequests.resetPassword(body);
    dispatch({
      type: "PASSWORD_RESET",
    });
  };

  const newPassword = async (body: NewPasswordBody) => {
    await AuthRequests.createNewPassword(body);
    dispatch({
      type: "PASSWORD_RESET",
    });
  };

  const value = React.useMemo(
    () => ({
      ...state,
      login,
      logout,
      forgotPassword,
      forgotPasswordReset,
      newPassword,
    }),
    [state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
