import useSWRImmutable from "swr/immutable";
import { ApiError } from "types/ApiErrors";

import { BrandingSettings } from "types/Tables";

export const useBranding = (slug: string) => {
  const { data, error } = useSWRImmutable<BrandingSettings, ApiError>(
    slug.length ? `branding/${slug}` : null
  );

  return {
    brand: data,
    loading: !data && !error,
  };
};
