import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import React from "react";

import { useBranding } from "hooks/useBranding";
import { useSettings } from "hooks/useSettings";
import { useSlugRouter } from "hooks/useSlugRouter";
import { createTheme } from "theme";

export const ThemeProvider: React.FC = ({ children }) => {
  const { settings } = useSettings();
  const { slug } = useSlugRouter();
  const { brand } = useBranding(slug);

  const theme = createTheme(
    {
      responsiveFontSizes: settings.responsiveFontSizes,
      roundedCorners: settings.roundedCorners,
      theme: settings.theme,
    },
    brand?.color
  );

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
