import React, { createContext, useCallback, useEffect } from "react";

export interface FilterContextValue {
  filterValues: Record<string, string[]>;
  saveFilterValues: (values: Record<string, string[]>) => void;
}

/**
 * Restore filters from local storage
 *   In case storedData is invalid, we catch the JSON error and return empty object
 * @returns Filters from local storage
 */
const restoreFilters = (): Record<string, string[]> => {
  let filters: Record<string, string[]> = {};

  try {
    const storedData: string | null = window.localStorage.getItem("filters");
    if (storedData) {
      filters = JSON.parse(storedData) as Record<string, string[]>;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return filters;
};

const storeFilters = (values: Record<string, string[]>) => {
  window.localStorage.setItem("filters", JSON.stringify(values));
};

export const FiltersContext = createContext<FilterContextValue>({
  filterValues: {},
  saveFilterValues: () => {
    // Do nothing
  },
});

export const FiltersProvider: React.FC = (props) => {
  const { children } = props;
  const [filterValues, setFilterValues] = React.useState<
    Record<string, string[]>
  >({});

  useEffect(() => {
    const restoredFilters = restoreFilters();
    if (Object.keys(restoredFilters).length > 0) {
      setFilterValues(restoredFilters);
    }
  }, []);

  const saveFilterValues = useCallback(
    (updatedValues: Record<string, string[]>) => {
      setFilterValues((values) => {
        const newValues = { ...values, ...updatedValues };
        storeFilters(newValues);
        return newValues;
      });
    },
    []
  );

  const value = React.useMemo(
    () => ({
      filterValues,
      saveFilterValues,
    }),
    [filterValues, saveFilterValues]
  );

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};
