import * as React from "react";
import { loadWidget } from "@marker.io/browser";
import { isStaging } from "utils/is-staging";

export const useMarker = () => {
  React.useEffect(() => {
    if (isStaging()) {
      loadWidget({
        destination: "61fac0de089cbf499c3b56c6",
      })
        .then((widget) => {
          widget.show();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, []);
};
